import { defineStore } from 'pinia';
import type { ComponentInstance } from '../types';
import type {
  CmsMonetateExperienceComponentDataProps,
  Image,
  Seo,
} from '@vf/api-contract';
import {
  extractCommonConfigLink,
  extractProperty,
} from '../useCms/mappings/utils';
import { useCmsRefStore } from './cmsRef';

interface LoyaltyPdpBannerProps {
  imageObject: {
    images: Image;
    seo: Seo;
  };
  link: string;
}

export type MonetateExperienceProp = Pick<
  CmsMonetateExperienceComponentDataProps,
  | 'experienceId'
  | 'forcedVariantLabel'
  | 'baseline'
  | 'defaultVariant'
  | 'variants'
>;

interface State {
  klarnaEnabled: boolean;
  loyaltyEnrollment: string;
  monetateExperiencesPdp: MonetateExperienceProp[];
  monetateExperiencesPlp: MonetateExperienceProp[];
  seoWidgetRecommendationsEnabled: boolean;
  signInToBuy: string;
  loyaltyPdpBannerProps?: LoyaltyPdpBannerProps;
}

// getLoyaltyPdpBannerPropss deprecated, but is here just in case
/* const getLoyaltyPdpBannerProps = (): LoyaltyPdpBannerProps | undefined => {
    const loyaltyPdpBannerPicture = {
      data: { uri: envStaticPlpPdp.loyaltyPdpBanner?.imageUri },
      width: 400,
      height: 400,
    } as CMPicture;

    return envStaticPlpPdp.loyaltyPdpBanner?.targetId
      ? {
          imageObject: {
            seo: getMediaSeoAttributes(loyaltyPdpBannerPicture),
            images: getImageObject(
              loyaltyPdpBannerPicture,
              cmsRefStore.cmsSiteConfiguration,
              null,
              cmsRefStore.baseMediaUri,
              null
            ),
          },
          link: `[data-id='${envStaticPlpPdp.loyaltyPdpBanner.targetId}']`,
        }
      : undefined;
  }; */

const getMonetateExperienceProps = ({
  envMonetateExperience,
  monetateExtraProps,
  instance,
}): MonetateExperienceProp[] => {
  const locale = instance.$i18n.locale;
  const translations = instance.$t('monetateExperience');

  return (envMonetateExperience ?? [])
    .filter((item) => item.experienceId)
    .map((item) => {
      const getMonetateBaseline = () => ({
        component: 'monetate/MonetateProductRecommendations',
        attrs: { 'data-id': `monetate-decision-${item.experienceId}` },
        props: {
          translations: {
            ...translations,
            heading: translations[item.heading] || item.heading,
          },
          headingLevel: 3,
          headerFontWeight: null,
          headerTextAlign: 'left',
          showAddToFavorites: false,
          showQuickShopCta: false,
          showMobileOnTwoRows: false,
          isCarousel: true,
          isRandomized: false,
          slideFullRow: true,
          autoplay: false,
          loop: false,
          slideDuration: 2000,
          quickShopLink: `/${locale}/quickshop`,
          experienceId: item.experienceId,
          displayedProductsSmall: 2,
          displayedProductsMedium: 4,
          displayedProductsLarge: 6,
          _states: null,
          ...monetateExtraProps,
        },
      });

      return {
        experienceId: item.experienceId,
        forcedVariantLabel: null,
        baseline: {
          children: [getMonetateBaseline()],
        },
        defaultVariant: {
          children: [getMonetateBaseline()],
        },
        variants: [
          {
            label: 'A',
            content: {
              children: [getMonetateBaseline()],
            },
          },
        ],
      };
    });
};

const getSignInToBuy = (cmsRefStore) =>
  extractProperty(
    extractCommonConfigLink(cmsRefStore.cmsSiteConfiguration, 'sign-in'),
    '[0]'
  )?.id;

const getLoyaltyEnrollment = (cmsRefStore) =>
  extractProperty(
    extractCommonConfigLink(
      cmsRefStore.cmsSiteConfiguration,
      'loyalty-enrollment-modal'
    ),
    '[0]'
  )?.id;

export const useStaticLayoutProps = (instance: ComponentInstance) =>
  defineStore('staticLayoutProps', {
    state: (): State => {
      const cmsRefStore = useCmsRefStore();
      const envStaticPlpPdp: any = instance.$getEnvValueByCurrentLocale(
        'STATIC_PLP_PDP'
      );
      const loyaltyEnrollment = getLoyaltyEnrollment(cmsRefStore);
      const signInToBuy = getSignInToBuy(cmsRefStore);

      const monetateModalsProps = {
        modals: { loyaltyEnrollment, signInToBuy },
      };

      return {
        klarnaEnabled: envStaticPlpPdp.klarna?.enabled || false,
        loyaltyEnrollment,
        loyaltyPdpBannerProps: undefined,
        monetateExperiencesPdp: getMonetateExperienceProps({
          envMonetateExperience: envStaticPlpPdp.monetateExperiencePdp,
          monetateExtraProps: {
            ...monetateModalsProps,
            ...instance.$themeSettings.MonetateProductRecommendations,
          },
          instance,
        }),
        monetateExperiencesPlp: getMonetateExperienceProps({
          envMonetateExperience: envStaticPlpPdp.monetateExperiencePlp,
          monetateExtraProps: monetateModalsProps,
          instance,
        }),
        seoWidgetRecommendationsEnabled:
          envStaticPlpPdp.seoWidgetRecommendations?.enabled || false,
        signInToBuy,
      };
    },
  })(instance.$pinia);
