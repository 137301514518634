import { parseCmsPlacement } from '../dataTransformators/utils/transformPageContentData';
import { camelCase } from '../dataTransformators/utils/camelCase';
import { standardComponentDataParser } from '../dataTransformators/utils/components/standard';

export const extractDynamicSlots = (
  data,
  { siteConfiguration, cmsBaseUri, requirements, context, pageTypeName },
  errors
) => {
  const componentParser = standardComponentDataParser(
    siteConfiguration,
    context as any,
    cmsBaseUri,
    requirements,
    pageTypeName
  );

  return (
    data.content?.grid.rows.reduce((acc, row) => {
      row.placements.forEach((placement) => {
        acc[camelCase(placement.name)] = parseCmsPlacement(placement, {
          componentParser,
          siteConfiguration,
          cmsBaseUri,
          requirements,
          context,
          pageTypeName,
          errors,
        });
      });
      return acc;
    }, {}) || {}
  );
};
