







































































import debounce from '@vf/shared/src/utils/helpers/debounce';
import {
  defineComponent,
  computed,
  ref,
  watch,
  onMounted,
  onUpdated,
  onBeforeUnmount,
  Ref,
  ComputedRef,
  inject,
} from '@vue/composition-api';
import ClickOutside from 'vue-click-outside';

export default defineComponent({
  name: 'VfTooltip',
  directives: {
    ClickOutside,
  },
  props: {
    /** tooltip id for aria */
    id: {
      type: String,
      default: null,
    },
    /** If true clicking outside will not dismiss the tooltip */
    persistent: {
      type: Boolean,
      default: false,
    },
    /** If true tooltip is visible */
    isVisible: {
      type: Boolean,
      default: false,
    },
    /** close button text */
    closeText: {
      type: String,
      default: 'Close',
    },
    /** will the parent call an action on close? */
    callMethodOnClose: {
      type: Boolean,
      default: false,
    },
    /** action close button text - callMethodOnClose is true */
    closeActionText: {
      type: String,
      default: '',
    },
    /** tooltip type primary/secondary */
    modifier: {
      type: String,
      default: 'primary',
    },
    /** tooltip extra class */
    tooltipClass: {
      type: String,
    },
  },
  setup(props, { root, emit }) {
    const isCoreRedesignEnabled: { value: boolean } = inject(
      'isCoreRedesignEnabled'
    );
    const popupItem = ref(null);
    const popupItemContainer = ref(null);
    const offsetLeft: Ref<number> = ref(0);
    const closeIcon = ref(root.$themeConfig?.tooltip?.closeIcon || 'cross');

    const buttonColor: ComputedRef<string> = computed(() =>
      props.modifier === 'primary' ? 'secondary' : 'primary'
    );

    const buttonVariant: ComputedRef<string> = computed(() =>
      props.modifier === 'primary' ? 'border' : ''
    );

    const iconColor: ComputedRef<string> = computed(() =>
      props.modifier === 'secondary' ? 'white' : ''
    );
    const additionalTooltipClass: ComputedRef<string> = computed(
      () => root.$themeConfig?.tooltip?.additionalTooltipClass || ''
    );
    const tooltipClass =
      props.tooltipClass ??
      root.$themeConfig?.orderSummarySidebar?.tooltipClass;

    const handleResize = () => {
      offsetLeft.value =
        (props.isVisible && popupItem.value?.getBoundingClientRect()?.left) ||
        0;
      if (root.$viewport?.isSmall) {
        const padding = window
          .getComputedStyle(popupItem.value)
          .getPropertyValue('--mobile-padding');
        popupItemContainer.value?.style.setProperty(
          'left',
          `calc(${-offsetLeft.value}px + ${padding})`
        );
        popupItemContainer.value?.style.setProperty(
          '--tooltip-width',
          `calc(${document.body.clientWidth}px - var(--mobile-padding) * 2)`
        );
      } else {
        if (
          popupItem.value?.classList.contains('vf-tooltip--right') ||
          popupItem.value?.classList.contains('vf-tooltip--left')
        ) {
          popupItemContainer.value?.style.removeProperty('left');
          return;
        }
        // check if popup is close to left edge and fix positioning
        if (
          offsetLeft.value <
          popupItem.value?.getBoundingClientRect().width / 2
        ) {
          popupItemContainer.value?.style.setProperty('left', 0);
          return;
        }
        popupItemContainer.value?.style.setProperty('left', `inherit`);
      }
    };
    const debouncedHandleResize = debounce(handleResize, 300);

    const clickOutsideHandler = () => !props.persistent && close();
    const close = () => props.isVisible && emit('click-close');
    // tooltip was not just dismissed a button tied too an action on the parent is clicked
    const actionClose = () => props.isVisible && emit('click-action-close');

    const addClass = () => popupItem.value?.classList.add('vf-tooltip--bottom');
    const removeClass = () =>
      popupItem.value?.classList.remove('vf-tooltip--bottom');

    watch(
      () => props.isVisible,
      (val) => !val && removeClass()
    );
    onUpdated(() => {
      handleResize();
    });
    onMounted(() => {
      handleResize();
      window.addEventListener('resize', debouncedHandleResize);

      if (tooltipClass && isCoreRedesignEnabled?.value) {
        popupItem.value?.classList.add(tooltipClass);
      }
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', debouncedHandleResize);
    });

    return {
      popupItem,
      popupItemContainer,
      offsetLeft,
      buttonColor,
      buttonVariant,
      iconColor,
      additionalTooltipClass,
      clickOutsideHandler,
      close,
      actionClose,
      addClass,
      removeClass,
      closeIcon,
    };
  },
});
