//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'ProgressBar',
  props: {
    range: {
      type: Object,
      default: () => ({
        from: 0,
        to: 100,
      }),
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      isCoreRedesignEnabled,
    };
  },
  computed: {
    fillerStyles() {
      return {
        width: (this.value / this.range.to) * 100 + '%',
      };
    },
  },
});
