//
//
//
//

import { onBeforeMount } from '@vue/composition-api';
import { useCart, useAuthentication, useI18n, ROUTES } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
export default {
  setup() {
    const { root } = useRootInstance();
    const { applyShared } = useCart(root);
    const { getConsumerId } = useAuthentication(root);
    const { localePath } = useI18n(root);

    onBeforeMount(async () => {
      if (root.$route.query.scid) {
        await applyShared(root.$route.query.scid, getConsumerId.value);
      }
      root.$router.push(localePath(ROUTES.CART()));
    });

    return {
      path: root.$route.path.replace('/share', ''),
    };
  },
};
