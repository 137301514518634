
























import { computed, defineComponent, PropType } from '@vue/composition-api';
import { LoyaltyInterestsItemProp } from '../types';

export default defineComponent({
  name: 'LoyaltyInterestsItem',
  props: {
    item: {
      type: Object as PropType<LoyaltyInterestsItemProp>,
      default: () => ({}),
    },
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    const icon = computed(() => (props.item.isActive ? 'correct' : 'plus'));

    const onItemToggle = () => {
      emit('toggle', props.item);
    };

    return {
      icon,
      onItemToggle,
    };
  },
});
