




















import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  watch,
  ref,
} from '@vue/composition-api';
import debounce from '@vf/shared/src/utils/helpers/debounce';

/**
 * very important to use that component your parent need to define --vf-collapsible-chips--base-max-height
 */
export default defineComponent({
  name: 'VfCollapsibleChips',
  props: {
    disabled: Boolean,
    observe: { type: Array, default: () => [] },
    resetOnChange: {
      type: [String, Number],
      default: '',
    },
    lightVersion: {
      // use light version when you have simple element (no dropdown).. this version improve the performance
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const refCollapsibleChips = ref<HTMLDivElement>(null);
    const isCollapsible = ref(false);
    const isCollapsed = ref(true);
    const isReady = ref(false);

    const HIDE_ITEM = 'vf-collapsible-chips--hide-item';
    const CALCULATING = 'vf-collapsible-chips--calculating';
    const ANIMATION = 'vf-collapsible-chips--animation';

    const showOnlyFirstLine = () => {
      // hide not visible element
      const children = refCollapsibleChips.value.children;
      for (
        let i = refCollapsibleChips.value.childElementCount - 1;
        i >= 0;
        i--
      ) {
        if (children[i].classList.contains('vf-collapsible-chips__cta'))
          continue;

        if ((children[i] as HTMLElement).offsetTop !== 0) {
          children[i].classList.add(HIDE_ITEM);
        } else {
          break;
        }
      }
    };

    const showAll = () => {
      refCollapsibleChips.value
        .querySelectorAll('.' + HIDE_ITEM)
        .forEach((child) => child.classList.remove(HIDE_ITEM));
    };

    const checkCollapsible = () => {
      refCollapsibleChips.value.scrollTop = 0;
      isCollapsible.value =
        (refCollapsibleChips.value.lastElementChild as HTMLElement)
          .offsetTop !== 0;
    };

    const renderCollapsible = debounce(() => {
      requestAnimationFrame(async () => {
        showAll();
        checkCollapsible();
        await nextTick();
        if (isCollapsed.value) {
          showOnlyFirstLine();
        }
        isReady.value = true;
      });
    }, 500);

    const renderCollapsibleLight = debounce(() => {
      requestAnimationFrame(() => {
        checkCollapsible();

        if (!isCollapsed.value) {
          // update maxHeight when is open
          refCollapsibleChips.value.style.setProperty(
            '--vf-collapsible-chips--to',
            refCollapsibleChips.value.scrollHeight + 'px'
          );
        }

        isReady.value = true;
      });
    }, 500);

    const removeCalculatingClass = debounce(() => {
      // remove maxHeight
      refCollapsibleChips.value.classList.remove(CALCULATING);
    }, 600);

    const prepareRenderCollapsible = () => {
      if (props.disabled || refCollapsibleChips.value === null) return;

      if (props.lightVersion) {
        renderCollapsibleLight();
      } else {
        if (isCollapsed.value) {
          // add maxHeight, this reduce jump when float elements are moving
          refCollapsibleChips.value.classList.add(CALCULATING);
          removeCalculatingClass();
        }
        renderCollapsible();
      }
    };

    const animation = (from, to) => {
      return new Promise((resolve) => {
        refCollapsibleChips.value.style.setProperty(
          '--vf-collapsible-chips--from',
          from
        );
        refCollapsibleChips.value.style.setProperty(
          '--vf-collapsible-chips--to',
          to
        );

        refCollapsibleChips.value.addEventListener(
          'animationend',
          () => {
            refCollapsibleChips.value.classList.remove(ANIMATION);
            resolve(true);
          },
          { once: true }
        );

        refCollapsibleChips.value.classList.add(ANIMATION);
      });
    };

    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
      // animation
      const closeHeight = 'var(--vf-collapsible-chips--base-max-height)'; // closed
      const openHeight = refCollapsibleChips.value.scrollHeight + 'px'; // open
      requestAnimationFrame(() => {
        if (props.lightVersion) {
          isCollapsed.value
            ? animation(openHeight, closeHeight)
            : animation(closeHeight, openHeight);
        } else {
          if (isCollapsed.value) {
            // close collapse
            animation(openHeight, closeHeight).then(showOnlyFirstLine);
          } else {
            // open collapse
            showAll();
            animation(
              closeHeight,
              refCollapsibleChips.value.scrollHeight + 'px'
            );
          }
        }
      });
    };

    const reset = () => {
      isCollapsed.value = true;
    };

    onMounted(() => {
      watch(
        () => props.observe,
        () => {
          prepareRenderCollapsible();
        },
        { immediate: true }
      );

      watch(
        () => props.disabled,
        (isDisabled) => {
          isDisabled
            ? window.removeEventListener('resize', prepareRenderCollapsible)
            : window.addEventListener('resize', prepareRenderCollapsible);
        },
        { immediate: true }
      );

      watch(() => props.resetOnChange, reset);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', prepareRenderCollapsible);
    });

    return {
      isCollapsed,
      isCollapsible,
      isReady,
      refCollapsibleChips,
      toggleCollapse,
    };
  },
});
