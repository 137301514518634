import { Context } from '@vf/api-contract';
import {
  onMounted,
  onBeforeUnmount,
  useFetch,
  inject,
  computed,
  nextTick,
  ref,
  Ref,
} from '@nuxtjs/composition-api';
import { isClient } from '@vf/shared/src/utils/helpers';
import { ComposableContext, ComponentInstance } from '../../../types';
import {
  useCategory,
  useFilters,
  useNuanceChat,
  useFavorites,
  useSignInToStore,
  useTrackPage,
} from '../../../index';
import { useCmsRefStore } from '../../../store/cmsRef';
import { useStaticLayoutProps } from '../../../store/staticLayoutProps';
import type { MonetateExperienceProp } from '../../../store/staticLayoutProps';
import { getPageCanonicalUrl } from '../../../useCms/utils/getPageCanonicalUrl';
import { fetchPageContent } from '../../../useCms/dataFetcher/pages';
import {
  CmsEspot,
  InitializedApiClient,
  PageTypeName,
} from '../../../useCms/types';
import { sanitizeCmsEspotsResponse } from '../../../useCms/dataTransformators';
import { getEspots } from '../../../useCms/utils';
import { extractDynamicSlots } from '../../../useCms/utils/extractDynamicSlots';
import { useFeatureFlagsStore } from '../../../store/featureFlags';

type PlpDynamicSlots = {
  aboveGrid?: {
    children?: unknown[];
    props?: {
      name?: string;
    };
  };
  dynamicContent?: {
    children?: unknown[];
    props?: {
      name?: string;
    };
  };
  seoText?: {
    children?: unknown[];
    props?: {
      name?: string;
    };
  };
};

const getPageMeta = (instance, meta, category, categoryId) => {
  const pageUrl = instance.$route.fullPath;
  const htmlTitle = meta.value?.title?.content || category.value;
  const ogDes = meta.value['og:description']?.content || undefined;
  const ogTitle = meta.value['og:title']?.content || undefined;
  return {
    canonicalUrl: getPageCanonicalUrl(pageUrl, instance),
    htmlTitle,
    commerceTags: meta.value,
    twitterCards: {
      tcDescription: ogDes,
      tcTitle: ogTitle,
    },
    analyticsTags: {
      categoryId: categoryId.value,
    },
  } as any;
};

const getPageEspotsFromContent = (
  { content },
  { siteConfiguration, context, cmsBaseUri, requirements }
): CmsEspot[] => {
  if (!content) {
    return [];
  }
  const espots = getEspots(content);
  const espotsSanitized = sanitizeCmsEspotsResponse(
    espots,
    siteConfiguration,
    context,
    cmsBaseUri,
    requirements,
    PageTypeName.PLP
  );

  return espotsSanitized.data;
};

export const usePlp = (
  instance: ComponentInstance,
  { handlePreviewModeClientSide, openModal, useUserData }: Record<string, any>
) => {
  const contextKey = Context.PageContent;
  const pageTypeName = PageTypeName.PLP;
  const { showFiltersBar } = useFilters(instance);
  let isLoadPageGTMEventsStarted = false;
  const cmsRefStore = useCmsRefStore(instance.$pinia);
  const staticLayoutProps = useStaticLayoutProps(instance);
  const { dispatchPageGTMEvents } = useTrackPage(instance);
  const themeConfigStaticPlp = instance.$themeConfig.staticPLP;
  const {
    areMonetateExperiencesSetInCmsForTemplatizedPages,
    isVansPlpRedesignEnabled,
  } = useFeatureFlagsStore();
  const dynamicSlots: Ref<PlpDynamicSlots> = ref({} as PlpDynamicSlots);

  const monetateExperiencesPlp = ref([] as MonetateExperienceProp[]);

  cmsRefStore.$patch({
    pageTypeName,
    facetConfiguration: themeConfigStaticPlp?.facetConfig,
  });
  const {
    changeSort,
    setActiveCategorySlug,
    setActiveCategoryId,
    setActivePage,
    resetFiltersView,
    readFiltersDataFromUrl,
    resetPagination,
    getCatalog,
    setInitialLoadProductsAmount,
    meta,
    category,
    categoryId,
    resetState,
    isBlankCategoryResponse,
  } = useCategory(instance, contextKey);
  const { getFavorites } = useFavorites(instance);
  const { employeeConnected } = useSignInToStore(instance);
  const cmsApiClient: Ref<Ref<InitializedApiClient>> = inject('cmsApiClient');
  const context: ComposableContext = { instance: instance, contextKey };

  const loadPage = async () => {
    try {
      nextTick(() => {
        process.client && window.$nuxt.$loading.start();
      });
      const path = instance.$route.path.split('/');
      const id = path[path.length - 1];
      resetFiltersView();
      readFiltersDataFromUrl();
      cmsRefStore.$patch({ breadcrumbs: [] });
      const pageNumber = 1;
      const sort = themeConfigStaticPlp?.categorySort.selectedSort;
      const parts = id.split('-');
      const categoryName = parts[parts.length - 1].slice(1);
      parts.pop();
      setActivePage(pageNumber);
      setActiveCategorySlug(categoryName);
      setActiveCategoryId(categoryName);
      setInitialLoadProductsAmount(
        themeConfigStaticPlp.categoryProducts.initialProductsLoad
      );
      changeSort(sort, false);
      await getCatalog(
        {},
        {
          isBackgroundRequest: false,
          useCache: !instance.$themeConfig.productsGrid?.loadPrevious
            .isLoadPreviousEnabled,
        }
      );
      if (isBlankCategoryResponse.value) {
        process.client && window.$nuxt.$loading.finish();
        return;
      }
      const pageMeta = getPageMeta(instance, meta, category, categoryId);

      // category content banner
      const content = await fetchPageContent(
        'category',
        context,
        cmsRefStore.cmsSiteConfiguration,
        cmsApiClient.value.value,
        categoryName,
        ''
      );
      const espots: CmsEspot[] = getPageEspotsFromContent(content, {
        context,
        siteConfiguration: cmsRefStore.cmsSiteConfiguration,
        requirements: cmsRefStore.requirements,
        cmsBaseUri: cmsRefStore.baseUri,
      });

      dynamicSlots.value = extractDynamicSlots(
        content,
        {
          siteConfiguration: cmsRefStore.cmsSiteConfiguration,
          cmsBaseUri: cmsRefStore.baseUri,
          requirements: cmsRefStore.requirements,
          context,
          pageTypeName: PageTypeName.PLP,
        },
        cmsRefStore.errors
      );

      if (areMonetateExperiencesSetInCmsForTemplatizedPages) {
        dynamicSlots.value.dynamicContent?.children.forEach((child: any) => {
          monetateExperiencesPlp.value.push(child.props);
        });
      } else {
        monetateExperiencesPlp.value = staticLayoutProps.monetateExperiencesPlp;
      }

      cmsRefStore.$patch({
        espots,
      });

      cmsRefStore.$patch({
        meta: pageMeta,
      });
      setTimeout(() => {
        nextTick(() => {
          process.client && window.$nuxt.$loading.finish();
        });
      }, 2000);
    } catch (error) {
      instance.$log.error(
        `@composables/useStaticLayout/composables/usePlp::loadPage Error: ${error.message}`,
        {
          path: instance.$route.path,
          error,
        }
      );

      throw new Error(
        `@composables/useStaticLayout/composables/usePlp::loadPage Error: ${error.message}`
      );
    }
  };

  const skipEvents = computed(
    () =>
      cmsRefStore.stateRedirectUrlExecuted ||
      cmsRefStore.canonicalRedirectUrlExecuted
  );

  const loadPageGTMEvents = async () => {
    if (
      [!isClient, skipEvents.value, isLoadPageGTMEventsStarted].some(Boolean)
    ) {
      return;
    }
    isLoadPageGTMEventsStarted = true;
    await dispatchPageGTMEvents({
      categoryID: categoryId.value,
      contextKey,
      openModal,
      pageTypeName: { value: pageTypeName },
      trackAfterSSR: { value: true },
      useUserData,
    });
    isLoadPageGTMEventsStarted = false;
  };
  loadPageGTMEvents();

  useFetch(async () => {
    await loadPage();
    loadPageGTMEvents();
    useNuanceChat(instance);
  });

  onMounted(() => {
    // TODO: GLOBAL15-63799 update when redesign work is done
    if (!employeeConnected.value && !isVansPlpRedesignEnabled) getFavorites();
    if (instance.$isPreview) {
      handlePreviewModeClientSide(instance, cmsRefStore.cmsSiteConfiguration);
    }
  });

  onBeforeUnmount(() => {
    let newUrl = `${window.location.origin}${window.location.pathname}`;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('page');
    if (urlParams.toString()) newUrl += `?${urlParams.toString()}`;
    if (window.location.hash) newUrl += window.location.hash;
    window.history.replaceState(
      {
        ...(window.history.state || {}),
        path: newUrl,
      },
      '',
      newUrl
    );
    resetState();
    resetPagination();
  });

  // This can be removed once all the domains use static PLP and these props
  // can be used directly where they are needed instead of passing them down
  // to children components
  const categoryProductsModalsProps = {
    signInToBuy: staticLayoutProps.signInToBuy,
    loyaltyEnrollment: staticLayoutProps.loyaltyEnrollment,
  };

  return {
    meta: cmsRefStore.meta,
    showFiltersBar,
    monetateExperiencesPlp,
    dynamicSlots,
    categoryProductsModalsProps,
    seoWidgetRecommendationsEnabled:
      staticLayoutProps.seoWidgetRecommendationsEnabled,
    isBlankCategoryResponse,
    contextKey,
  };
};
