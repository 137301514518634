var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-checkout"},[_c('Placement',{attrs:{"name":"main"}},[_c('VfContainer',{staticClass:"vf-checkout__container",attrs:{"full-height":""}},[_c('VfRow',{attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12"}},[_c('h1',{staticClass:"vf-checkout__heading"},[_vm._v("\n            "+_vm._s(_vm.$t('checkout.title'))+"\n          ")]),_vm._v(" "),(
              _vm.dynamicSlots.belowCheckoutHeader &&
              _vm.dynamicSlots.belowCheckoutHeader.children.length
            )?_c('CmsDynamicSlot',{attrs:{"data":_vm.dynamicSlots.belowCheckoutHeader,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.belowCheckoutHeader.props.name}}):_vm._e(),_vm._v(" "),_c('Notifications'),_vm._v(" "),(_vm.customerFacingFlashes.length)?_c('CartNotifications',{attrs:{"current-step":_vm.currentStep}}):_vm._e(),_vm._v(" "),_c('DiscountNotification'),_vm._v(" "),(_vm.cartItemsWithFlashes.length)?_c('OrderSummaryProducts',{staticClass:"checkout-small-only",attrs:{"is-cart-updates":"","products":_vm.cartItemsWithFlashes,"flashes":_vm.customerFacingFlashes}}):_vm._e(),_vm._v(" "),(!_vm.checkoutOrderSummaryUpdated)?_c('OrderSummaryProducts',{staticClass:"checkout-small-only",attrs:{"products":_vm.cartItems},on:{"click:edit-cart":_vm.editCart}}):_vm._e(),_vm._v(" "),(!_vm.isLoggedIn && _vm.showRewardsGuestInCheckout)?_c('LoyaltyGuest',{staticClass:"checkout-small-only"}):_vm._e()],1)],1),_vm._v(" "),_c('VfRow',{attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"7","large":"8"}},[(_vm.cartItemsWithFlashes.length)?_c('OrderSummaryProducts',{staticClass:"checkout-large-only",attrs:{"is-cart-updates":"","products":_vm.cartItemsWithFlashes,"flashes":_vm.customerFacingFlashes}}):_vm._e(),_vm._v(" "),(
              _vm.dynamicSlots.aboveCheckoutSteps &&
              _vm.dynamicSlots.aboveCheckoutSteps.children.length
            )?_c('CmsDynamicSlot',{staticClass:"vf-checkout__espot-above-checkout-steps",attrs:{"data":_vm.dynamicSlots.aboveCheckoutSteps,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.aboveCheckoutSteps.props.name}}):_vm._e(),_vm._v(" "),_c('CheckoutStep',{attrs:{"context":_vm.CheckoutContext.Shipping,"active-step":_vm.currentStep},on:{"change:step":function($event){return _vm.setShippingStep()}},scopedSlots:_vm._u([{key:"completed",fn:function(){return [_c('ShippingReview')]},proxy:true}])},[(_vm.shippingInfoReady)?_c('ShippingStepComponents',{ref:"shippingStepRef",on:{"click:save-shipping-step":_vm.setPaymentStep}}):_vm._e()],1),_vm._v(" "),_c('CheckoutStep',{ref:"payment",attrs:{"context":_vm.CheckoutContext.Payment,"active-step":_vm.currentStep},on:{"change:step":function($event){return _vm.setPaymentStep(false)}},scopedSlots:_vm._u([(_vm.currentStep !== _vm.CheckoutContext.Shipping)?{key:"completed",fn:function(){return [_c('CheckoutPaymentReview')]},proxy:true}:null],null,true)},[_c('PaymentStepComponents',{attrs:{"is-place-order-in-progress":_vm.isPlaceOrderInProgress},on:{"click:save-payment-step":function($event){return _vm.setReviewStep(_vm.checkoutSkipReviewStep)}}})],1)],1),_vm._v(" "),_c('VfColumn',{attrs:{"small":"12","medium":"5","large":"4"}},[_c('div',{staticClass:"vf-checkout__sidebar"},[(!_vm.checkoutOrderSummaryUpdated)?_c('OrderSummaryProducts',{staticClass:"checkout-large-only",attrs:{"products":_vm.cartItems},on:{"click:edit-cart":_vm.editCart}}):_vm._e(),_vm._v(" "),(
                _vm.checkoutRegisteredUserSingleStep &&
                _vm.currentStep === _vm.CheckoutContext.Review
              )?_c('CheckoutReward',{attrs:{"variant":"xplr-pass"}}):_vm._e(),_vm._v(" "),(_vm.isFamilyVouchersVisible)?_c('LazyStaticFamilyVouchersCompact'):_vm._e(),_vm._v(" "),(!_vm.checkoutOrderSummaryUpdated)?[(!_vm.isLoggedIn && _vm.showRewardsGuestInCheckout)?_c('LoyaltyGuest',{staticClass:"checkout-large-only"}):_vm._e(),_vm._v(" "),_c('PromoCodeSidebar'),_vm._v(" "),_c('OrderSummarySidebar',{attrs:{"current-step":_vm.currentStep,"show-divider":""}})]:_c('div',{staticClass:"checkout-large-only"},[(!_vm.isLoggedIn && _vm.showRewardsGuestInCheckout)?_c('LoyaltyGuest'):_vm._e(),_vm._v(" "),_c('OrderSummarySidebar',{attrs:{"current-step":_vm.currentStep,"show-divider":""}})],1)],2),_vm._v(" "),(_vm.currentStep === _vm.CheckoutContext.Review)?_c('PlaceOrderButton',{staticClass:"vf-checkout__place-order",attrs:{"disabled":!_vm.cartItems.length || _vm.isPlaceOrderInProgress},on:{"place-order":function($event){return _vm.placeOrder()}}}):_vm._e(),_vm._v(" "),(
              _vm.dynamicSlots.belowOrderSummary &&
              _vm.dynamicSlots.belowOrderSummary.children.length
            )?_c('CmsDynamicSlot',{staticClass:"vf-checkout__espot-below-order-summary",attrs:{"data":_vm.dynamicSlots.belowOrderSummary,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.belowOrderSummary.props.name}}):_vm._e()],1)],1)],1)],1),_vm._v(" "),_c('Placement',{attrs:{"name":"pagetype","has-items":false}}),_vm._v(" "),_c('VfModal',{staticClass:"vf-checkout__modal",attrs:{"visible":_vm.threeDSModalOpen}},[_c('div',{attrs:{"id":"cc-3ds2"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }