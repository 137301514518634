









import { computed, defineComponent, PropType } from '@vue/composition-api';
import useRootInstance from '../../theme/shared/useRootInstance';

export default defineComponent({
  name: 'VfDate',
  props: {
    date: {
      type: String,
      default: '',
    },
    format: {
      type: String as PropType<'' | 'intl-long'>,
      default: '',
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const parsedDate = computed(() => {
      if (props.format === 'intl-long') {
        return new Intl.DateTimeFormat(root.$i18n.locale, {
          dateStyle: 'long',
        }).format(new Date(props.date));
      }
      return props.date;
    });
    return {
      parsedDate,
    };
  },
});
