
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfChip',
  functional: true,
  props: {
    value: String,
    active: Boolean,
    link: [String, Object],
    rel: String,
    color: String,
  },
  render(h, { props, data, listeners }) {
    return h(
      'ThemeButton',
      {
        ...data,
        props: {
          color: props.color || 'secondary',
          ...(!props.active && { variant: 'border' }),
          size: 'sm',
          rel: props.rel,
          to: props.link,
        },
        on: {
          ...listeners,
          click: () => {
            listeners[`click-${props.active ? 'remove' : 'select'}`]?.();
          },
        },
        nativeOn: data.nativeOn,
      },
      props.value
    );
  },
});
