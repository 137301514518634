var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order)?_c('div',{staticClass:"vf-checkout-success"},[_c('Placement',{attrs:{"name":"main"}},[_c('VfContainer',{staticClass:"vf-checkout-success__container",attrs:{"full-height":""}},[_c('VfRow',{staticClass:"vf-notification__wrapper",attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"12","large":"12"}},[_c('VfNotification',{attrs:{"type":"success","visible":"","show-close-button":_vm.$themeConfig.staticCheckout.orderSuccess.showCloseIcon,"message":_vm.$t('orderSuccess.notification')}}),_vm._v(" "),(_vm.bounceBackVouchers.length)?_vm._l((_vm.bounceBackVouchers),function(voucher){return _c('VfNotification',{key:voucher.discount,staticClass:"vf-checkout-success__bounceBackVoucher",attrs:{"type":"success","visible":"","show-close-button":false,"message":_vm.$t('promoNotifications.VBBAppliedOnOrder', {
                  discount: _vm.bounceBackVouchers[0].discount,
                })}})}):_vm._e()],2)],1),_vm._v(" "),_c('VfRow',{attrs:{"full-height":""}},[_c('VfColumn',{attrs:{"small":"12","medium":"7","large":"8"}},[_c('OrderConfirmation',{attrs:{"order":_vm.order}}),_vm._v(" "),(_vm.orderPickups.length)?_c('PickupInformation',{staticClass:"vf-checkout-success__pickup"}):_vm._e(),_vm._v(" "),(
              _vm.dynamicSlots.aboveOrderItems &&
              _vm.dynamicSlots.aboveOrderItems.children.length
            )?_c('CmsDynamicSlot',{staticClass:"vf-checkout-success__espot-above-order-items",attrs:{"data":_vm.dynamicSlots.aboveOrderItems,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.aboveOrderItems.props.name}}):_vm._e(),_vm._v(" "),_vm._l((_vm.orderShipmentsWithItems),function(shipment){return _c('div',{key:shipment.shipmentId},[_c('h5',{key:((shipment.shipmentId) + "-heading"),staticClass:"vf-checkout-success__arrival"},[_c('p',{staticClass:"vf-checkout-success__arrival-label"},[_vm._v("\n                "+_vm._s(shipment.estimatedArrival)+"\n              ")]),_vm._v(" "),_c('small',{staticClass:"vf-checkout-success__count"},[_vm._v("\n                "+_vm._s(shipment.itemsCount)+"\n              ")])]),_vm._v(" "),(shipment.shipmentId === 'customs')?_c('div',{staticClass:"vf-checkout-success__customs-label"},[_vm._v("\n              "+_vm._s(_vm.$t('orderSuccess.customsLabel'))+"\n            ")]):_vm._e(),_vm._v(" "),_vm._l((shipment.items),function(product){return _c('VfThemeProductCard',{key:product.id,staticClass:"vf-checkout-success__product",attrs:{"product":product,"config":_vm.$themeConfig.staticCheckout.productCard,"is-compact":""},scopedSlots:_vm._u([(product.productPromotions)?{key:"promotionDescription",fn:function(){return [_c('div',{staticClass:"vf-checkout-success__promotions"},_vm._l((product.productPromotions),function(promotion){return _c('span',{key:promotion},[_vm._v("\n                    "+_vm._s(promotion)+"\n                  ")])}),0)]},proxy:true}:null,{key:"bottomContent",fn:function(){return [(_vm.$themeConfig.staticCart.giftOptionEnabled)?_c('GiftOptionSummary',{staticClass:"vf-cart-products__gift-option",attrs:{"product":product}}):_vm._e()]},proxy:true}],null,true)})})],2)}),_vm._v(" "),_c('SuccessReview')],2),_vm._v(" "),_c('VfColumn',{attrs:{"small":"12","medium":"5","large":"4"}},[(
              _vm.dynamicSlots.aboveOrderSummary &&
              _vm.dynamicSlots.aboveOrderSummary.children.length
            )?_c('CmsDynamicSlot',{staticClass:"vf-checkout-success__espot-above-order-summary",attrs:{"data":_vm.dynamicSlots.aboveOrderSummary,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.aboveOrderSummary.props.name}}):_vm._e(),_vm._v(" "),_c('OrderSummarySidebar',{attrs:{"current-step":_vm.CheckoutContext.Success,"show-divider":""}}),_vm._v(" "),_c('ThemeButton',{staticClass:"vf-checkout-success__print-button",attrs:{"color":"primary","size":"full","data-testid":"checkout-success-print-button","aria-label":_vm.$t('orderSuccess.printButtonLabel')},on:{"click":_vm.printReceipt}},[_vm._v("\n            "+_vm._s(_vm.$t('orderSuccess.printButtonLabel'))+"\n          ")]),_vm._v(" "),_c('InformationalSidebar',{staticClass:"vf-checkout-success__informational-sidebar"}),_vm._v(" "),(
              _vm.dynamicSlots.belowOrderSummary &&
              _vm.dynamicSlots.belowOrderSummary.children.length
            )?_c('CmsDynamicSlot',{staticClass:"vf-checkout-success__espot-below-order-summary",attrs:{"data":_vm.dynamicSlots.belowOrderSummary,"context-key":_vm.contextKey,"name":_vm.dynamicSlots.belowOrderSummary.props.name}}):_vm._e()],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }