//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'CreditCardsSummary',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    hasCards: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object,
      required: true,
    },
    card: {
      type: Object,
      default: () => ({}),
    },
  },
});
