




import { useCms } from '@vf/composables';
import {
  useMeta,
  defineComponent,
  ref,
  useAsync,
} from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  layout: 'empty',
  setup() {
    const { root } = useRootInstance();
    const { getRobotsResource, setup } = useCms(root);
    useMeta({
      title: undefined,
      content: [
        {
          type: 'text/javascript',
        },
      ],
    });
    const robotsText = ref(null);
    useAsync(async () => {
      await setup({ forcePreview: root.$isPreview });

      try {
        const response = await getRobotsResource();
        robotsText.value = (response as {
          content: Record<string, unknown>;
        }).content.fragmentByFolder;
      } catch (e) {
        console.error('Could not fetch robots data', e);
      }
    });

    return {
      robotsText,
    };
  },
  head: {},
});
